import api from "./server";

export const requestCallbackService = async (body) => {
  try {
    const { data } = await api.post(`/common_publish/commonPublish`, body);

    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchApplicationsService = async ({ user_id }) => {
  try {
    const { data } = await api.get(`/applications`, {
      user_id,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
